import React from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { LeadStateProvider } from "../_lib/context";

import { LeadHeader } from "@leads/LeadHeader";
import { TestDrive } from "@leads/cards/TestDrive/Card";
import { Grid, GridItem } from "@leads/Grid";

const BasicView: LeadViewComponent = (props) => {
	return (
		<>
			<LeadHeader {...props} />

			<Grid>
				<GridItem sm={12}>
					<TestDrive />
				</GridItem>
			</Grid>
		</>
	);
};

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
		children: undefined,
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});
