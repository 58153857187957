import React, { useMemo } from "react";

import dayjs from "dayjs";

import type { IFlexTableRow, FlexTableColumnDef } from "~/components/FlexTable";
import { InfoCardTable } from "~/components/InfoCard/InfoCard";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
	return {
		testDriveTable: {
			margin: "8px 0",
			height: "auto",
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				display: "block",
			},
		},
		titleContainer: {
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "space-between",
			alignItems: "center",
			width: "100%",
		},
		title: {
			...theme.typography.tableTitle,
			display: "flex",
			flexFlow: "row nowrap",
			padding: "14px",
		},
		imgContainer: {
			"@media print": {
				display: "none !important",
			},
			[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
				width: "295px",
			},
			[theme.breakpoints.up(theme.breakpoints.values.md)]: {
				width: "384px",
			},
			[theme.breakpoints.up(theme.breakpoints.values.lg)]: {
				width: "475px",
			},
		},
		noVehicleListing: {
			display: "flex",
			flexDirection: "column",
			flexWrap: "nowrap",
			justifyContent: "center",
			alignItems: "center",
			margin: "15px",
			height: "100%",
		},
	};
});

export interface IVehicleListing {
	listingImageUrl?: string;
	vehicleStockNumber?: string;
	listingUrl?: string;
	listingTitle?: string;
}

export interface IAppointment {
	appointmentAt?: any;
	sourceUrl?: string;
	vehicleListing: IVehicleListing;
}

const getRows = (app: IAppointment): IFlexTableRow[] => {
	const appointmentDate =
		app.appointmentAt &&
		dayjs(app.appointmentAt).format("dddd, MMMM DD YYYY");
	const appointmentTime =
		app.appointmentAt && dayjs(app.appointmentAt).format("h:mma");
	const vehicleInformation = app.vehicleListing?.listingTitle
		? `${app.vehicleListing?.listingTitle} - Stock Number: ${app.vehicleListing?.vehicleStockNumber}`
		: "Link";

	const rows: IFlexTableRow[] = [
		{
			cells: ["Date:", appointmentDate || ""],
		},
		{
			cells: ["Time:", appointmentTime || ""],
		},
		{
			cells: [
				"Vehicle:",
				<a key={app.sourceUrl} href={app.sourceUrl}>
					{vehicleInformation}
				</a>,
			],
		},
	];

	return rows;
};

const TestDriveTable = (app: IAppointment) => {
	const styles = useStyles();
	const columns: FlexTableColumnDef[] = useMemo(
		() => [
			{
				width: "20%",
				style: {
					minWidth: "125px",
					paddingRight: "5px",
				},
			},
			{
				width: "80%",
				style: {
					fontWeight: "bold",
					wordBreak: "normal",
					overflowWrap: "anywhere",
				},
			},
		],
		[],
	);

	const rows = getRows(app);

	return (
		<InfoCardTable
			title={"Test Drive"}
			columns={columns}
			rows={rows}
			className={styles.testDriveTable}
			tableOpts={{
				columnWrap: {
					height: 300,
				},
				rowWidth: "auto",
			}}
			imgOpts={
				app.vehicleListing?.listingImageUrl
					? {
							src: app.vehicleListing?.listingImageUrl,
							alt:
								app.vehicleListing?.listingTitle ??
								"offer-vehicle-image",
							className: styles.imgContainer,
					  }
					: undefined
			}
		/>
	);
};

export default TestDriveTable;
