import React, { Fragment, useMemo } from "react";

import { useLeadState } from "@leads/context";

import { Loading } from "~/components/Loading";

import TestDriveTable from "./index";

import type { IAppointment } from "./index";

export const TestDrive = () => {
	const {
		state: { lead, loading },
	} = useLeadState();

	const content = useMemo(() => {
		const appointments: any[] = lead?.appointments?.nodes || [];
		if (loading) {
			return <Loading />;
		}
		return appointments.map((app: IAppointment, i) => (
			<Fragment key={i}>
				<TestDriveTable
					sourceUrl={
						app.vehicleListing?.listingUrl || lead.sourceUrl || ""
					}
					appointmentAt={app?.appointmentAt}
					vehicleListing={app?.vehicleListing}
				/>
			</Fragment>
		));
	}, [lead?.appointments?.nodes, loading, lead?.sourceUrl]);

	return <Fragment>{content}</Fragment>;
};
